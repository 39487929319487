export const locationsData = ['vancouver', 'richmond', 'burnaby']

export const vancouverGeoJson = {
  type: 'Feature',
  geometry: {
    type: 'Polygon',
    coordinates: [
      [
        [-123.133140563965, 49.1991043090821],
        [-123.133148193359, 49.1991500854493],
        [-123.133262634277, 49.1991539001465],
        [-123.133239746094, 49.1997566223145],
        [-123.132911682129, 49.1997947692872],
        [-123.133232116699, 49.1998329162599],
        [-123.134719848633, 49.2000007629396],
        [-123.13525390625, 49.20027923584],
        [-123.13801574707, 49.200927734375],
        [-123.140144348144, 49.2010383605957],
        [-123.140968322754, 49.2015419006349],
        [-123.140830993652, 49.2021942138673],
        [-123.141014099121, 49.2026176452637],
        [-123.142967224121, 49.2032279968263],
        [-123.144111633301, 49.2038879394532],
        [-123.144721984863, 49.2041664123536],
        [-123.144996643066, 49.2041664123536],
        [-123.145278930664, 49.204444885254],
        [-123.145835876465, 49.204444885254],
        [-123.146110534668, 49.2047233581544],
        [-123.146583557129, 49.2047805786133],
        [-123.147041320801, 49.2049827575685],
        [-123.147483825684, 49.2051963806152],
        [-123.147933959961, 49.2054176330566],
        [-123.148399353027, 49.2056121826173],
        [-123.149719238281, 49.2058334350587],
        [-123.150001525879, 49.2061119079591],
        [-123.150276184082, 49.2061119079591],
        [-123.150833129883, 49.2064476013184],
        [-123.151107788086, 49.2066650390625],
        [-123.151947021484, 49.2075004577637],
        [-123.155426025391, 49.2105102539063],
        [-123.157356262207, 49.2109069824219],
        [-123.15779876709, 49.2112922668456],
        [-123.157943725586, 49.2116317749023],
        [-123.158271789551, 49.2122383117677],
        [-123.158843994141, 49.2125854492188],
        [-123.159355163574, 49.2128486633301],
        [-123.159820556641, 49.2130737304689],
        [-123.160430908203, 49.2133674621583],
        [-123.161010742187, 49.2133331298828],
        [-123.162017822266, 49.2135734558107],
        [-123.162528991699, 49.21390914917],
        [-123.163055419922, 49.2143096923828],
        [-123.163551330566, 49.2144813537599],
        [-123.163940429688, 49.2145576477051],
        [-123.164489746094, 49.2146530151367],
        [-123.164703369141, 49.2146835327148],
        [-123.165382385254, 49.2147789001465],
        [-123.165939331055, 49.2151489257814],
        [-123.166305541992, 49.2152328491211],
        [-123.166961669922, 49.2152671813966],
        [-123.167610168457, 49.2155113220215],
        [-123.168182373047, 49.2156562805177],
        [-123.169090270996, 49.2160186767578],
        [-123.169326782227, 49.2164039611818],
        [-123.169982910156, 49.2163887023927],
        [-123.170639038086, 49.2162971496582],
        [-123.17147064209, 49.2163925170898],
        [-123.172050476074, 49.21635055542],
        [-123.172378540039, 49.21635055542],
        [-123.173217773437, 49.2162551879883],
        [-123.174049377441, 49.2162818908691],
        [-123.174652099609, 49.216495513916],
        [-123.175140380859, 49.216594696045],
        [-123.175842285156, 49.216495513916],
        [-123.176315307617, 49.2163581848145],
        [-123.177284240723, 49.2165374755859],
        [-123.177833557129, 49.2167510986328],
        [-123.178802490234, 49.2169036865236],
        [-123.178970336914, 49.2168006896974],
        [-123.179466247559, 49.2163810729981],
        [-123.180053710938, 49.2163887023927],
        [-123.180641174316, 49.2163963317872],
        [-123.180885314941, 49.2164306640626],
        [-123.181533813477, 49.2167510986328],
        [-123.182235717773, 49.2169570922852],
        [-123.182838439941, 49.2171821594239],
        [-123.183372497559, 49.2172966003419],
        [-123.183868408203, 49.2175025939942],
        [-123.184364318848, 49.217601776123],
        [-123.184875488281, 49.2176246643066],
        [-123.185371398926, 49.2178611755372],
        [-123.185859680176, 49.2180938720703],
        [-123.186004638672, 49.2184524536132],
        [-123.186302185059, 49.2187156677247],
        [-123.18675994873, 49.2189178466797],
        [-123.187370300293, 49.2191734313965],
        [-123.188117980957, 49.2193908691407],
        [-123.188446044922, 49.2197074890137],
        [-123.188682556152, 49.2199172973634],
        [-123.189338684082, 49.2203292846681],
        [-123.189758300781, 49.2204971313478],
        [-123.190200805664, 49.2206268310547],
        [-123.190650939941, 49.22074508667],
        [-123.191146850586, 49.2208824157715],
        [-123.191368103027, 49.220890045166],
        [-123.191215515137, 49.2255897521974],
        [-123.191101074219, 49.2264556884765],
        [-123.190856933594, 49.2275199890137],
        [-123.190872192383, 49.2276153564453],
        [-123.19100189209, 49.2283515930176],
        [-123.195037841797, 49.2297286987305],
        [-123.19701385498, 49.2305908203126],
        [-123.197105407715, 49.2329216003419],
        [-123.196990966797, 49.2349205017091],
        [-123.19694519043, 49.2356224060059],
        [-123.196937561035, 49.2364501953126],
        [-123.198493957519, 49.2364501953126],
        [-123.198417663574, 49.2385330200195],
        [-123.196899414062, 49.2385711669922],
        [-123.196891784668, 49.2395362854004],
        [-123.196899414062, 49.2405242919923],
        [-123.19686126709, 49.2414321899415],
        [-123.19686126709, 49.2424240112306],
        [-123.196853637695, 49.2433853149415],
        [-123.196853637695, 49.2443199157715],
        [-123.196846008301, 49.2452545166016],
        [-123.196807861328, 49.2462196350098],
        [-123.198928833008, 49.2462615966798],
        [-123.201171875, 49.247371673584],
        [-123.203056335449, 49.2482681274414],
        [-123.205360412598, 49.2492713928223],
        [-123.204078674316, 49.2492218017578],
        [-123.201499938965, 49.2500495910645],
        [-123.201354980469, 49.2500953674317],
        [-123.200805664062, 49.2501678466797],
        [-123.200035095215, 49.2501983642579],
        [-123.199485778809, 49.2502136230469],
        [-123.198745727539, 49.250202178955],
        [-123.196823120117, 49.2503242492676],
        [-123.196838378906, 49.2520408630372],
        [-123.196586608887, 49.2530174255372],
        [-123.196502685547, 49.2539367675781],
        [-123.196563720703, 49.2548065185547],
        [-123.19669342041, 49.2555885314941],
        [-123.196708679199, 49.2557754516603],
        [-123.196723937988, 49.2566375732422],
        [-123.198165893555, 49.2567100524902],
        [-123.201133728027, 49.2567405700684],
        [-123.203025817871, 49.2566490173341],
        [-123.203147888184, 49.256935119629],
        [-123.203224182129, 49.2574424743653],
        [-123.203262329102, 49.2576560974121],
        [-123.203231811523, 49.2583351135254],
        [-123.20629119873, 49.2583770751953],
        [-123.209274291992, 49.2584228515626],
        [-123.212272644043, 49.2584342956543],
        [-123.215560913086, 49.2584800720215],
        [-123.215507507324, 49.2595367431641],
        [-123.215461730957, 49.2603874206544],
        [-123.215408325195, 49.261287689209],
        [-123.215354919434, 49.2622070312501],
        [-123.215309143066, 49.2631492614747],
        [-123.215286254883, 49.2640380859374],
        [-123.215240478516, 49.264877319336],
        [-123.215202331543, 49.265724182129],
        [-123.215171813965, 49.2666244506837],
        [-123.215133666992, 49.2674522399903],
        [-123.219436645508, 49.2673721313477],
        [-123.219497680664, 49.2684478759766],
        [-123.219581604004, 49.2688713073732],
        [-123.221557617188, 49.2687187194824],
        [-123.221420288086, 49.2705116271973],
        [-123.222076416016, 49.2710342407228],
        [-123.223747253418, 49.272834777832],
        [-123.223724365234, 49.2748260498047],
        [-123.223838806152, 49.2751693725587],
        [-123.215957641602, 49.2733955383301],
        [-123.206878662109, 49.2729682922363],
        [-123.198867797852, 49.2748184204102],
        [-123.196807861328, 49.2739753723145],
        [-123.196083068848, 49.2729568481446],
        [-123.195198059082, 49.2729492187501],
        [-123.194343566895, 49.2725257873535],
        [-123.19393157959, 49.2727966308595],
        [-123.193778991699, 49.2728958129883],
        [-123.193252563477, 49.2730178833008],
        [-123.192764282227, 49.2727050781251],
        [-123.192085266113, 49.2727966308595],
        [-123.191474914551, 49.2730522155763],
        [-123.190879821777, 49.2731056213379],
        [-123.190277099609, 49.2731437683105],
        [-123.189834594727, 49.2733879089356],
        [-123.189079284668, 49.2733383178712],
        [-123.188667297363, 49.2736167907716],
        [-123.188056945801, 49.2738876342774],
        [-123.187774658203, 49.2738876342774],
        [-123.1875, 49.2736129760742],
        [-123.184997558594, 49.2736129760742],
        [-123.184722900391, 49.2733345031738],
        [-123.184448242188, 49.2733345031738],
        [-123.183372497559, 49.2729797363282],
        [-123.182243347168, 49.2725868225098],
        [-123.181663513184, 49.2726669311523],
        [-123.181007385254, 49.2726745605469],
        [-123.180564880371, 49.2725830078126],
        [-123.180107116699, 49.2724952697755],
        [-123.179473876953, 49.2722549438477],
        [-123.178810119629, 49.2722816467286],
        [-123.178428649902, 49.2721328735352],
        [-123.177749633789, 49.2721366882324],
        [-123.177124023437, 49.2721366882324],
        [-123.176963806152, 49.2722854614258],
        [-123.176734924316, 49.2721366882324],
        [-123.176574707031, 49.2720336914064],
        [-123.176261901855, 49.2721405029297],
        [-123.176010131836, 49.2722244262696],
        [-123.175796508789, 49.272186279297],
        [-123.175369262695, 49.2721099853516],
        [-123.174491882324, 49.2719497680665],
        [-123.172843933105, 49.2717170715333],
        [-123.171539306641, 49.2720336914064],
        [-123.170669555664, 49.2719383239747],
        [-123.170051574707, 49.272129058838],
        [-123.16951751709, 49.272129058838],
        [-123.169006347656, 49.2722244262696],
        [-123.168663024902, 49.2724418640137],
        [-123.168327331543, 49.2726783752443],
        [-123.167846679688, 49.2726516723633],
        [-123.167175292969, 49.2728691101075],
        [-123.166900634766, 49.2729949951171],
        [-123.166381835937, 49.2731590270996],
        [-123.165649414062, 49.2733573913575],
        [-123.165214538574, 49.2736930847168],
        [-123.164855957031, 49.2738533020019],
        [-123.164291381836, 49.2740173339844],
        [-123.164138793945, 49.2740211486818],
        [-123.163734436035, 49.2741279602051],
        [-123.163467407227, 49.2743263244629],
        [-123.163032531738, 49.2743453979492],
        [-123.162498474121, 49.2743606567383],
        [-123.161766052246, 49.2744445800782],
        [-123.161201477051, 49.2742881774902],
        [-123.16064453125, 49.2743377685546],
        [-123.160209655762, 49.2743873596193],
        [-123.159820556641, 49.2742767333986],
        [-123.159187316895, 49.2738761901855],
        [-123.158912658691, 49.2736473083497],
        [-123.158676147461, 49.2736930847168],
        [-123.158340454102, 49.2737274169923],
        [-123.157333374023, 49.2737655639648],
        [-123.156852722168, 49.2736854553223],
        [-123.156074523926, 49.2735977172852],
        [-123.155326843262, 49.2738494873047],
        [-123.155075073242, 49.2743835449219],
        [-123.154495239258, 49.2749862670899],
        [-123.153579711914, 49.275978088379],
        [-123.153030395508, 49.2762298583986],
        [-123.153076171875, 49.2767066955568],
        [-123.152694702148, 49.2769126892089],
        [-123.152770996094, 49.2775268554689],
        [-123.152603149414, 49.2779197692871],
        [-123.152236938477, 49.2781410217286],
        [-123.15160369873, 49.2781105041504],
        [-123.151023864746, 49.2780876159668],
        [-123.150428771973, 49.2780647277832],
        [-123.149833679199, 49.2779655456544],
        [-123.148834228516, 49.2781791687012],
        [-123.148406982422, 49.2786598205566],
        [-123.148124694824, 49.2788200378417],
        [-123.147590637207, 49.2787818908693],
        [-123.147026062012, 49.2784996032715],
        [-123.14672088623, 49.2781753540039],
        [-123.145858764648, 49.2782974243165],
        [-123.145469665527, 49.278663635254],
        [-123.145271301269, 49.2792167663574],
        [-123.144309997559, 49.2791709899903],
        [-123.143684387207, 49.2791213989258],
        [-123.143379211426, 49.2788543701173],
        [-123.142791748047, 49.2786941528321],
        [-123.142311096191, 49.2784042358398],
        [-123.141914367676, 49.2784080505372],
        [-123.1416015625, 49.2782173156739],
        [-123.141296386719, 49.2778854370118],
        [-123.140769958496, 49.2777786254883],
        [-123.140296936035, 49.2765121459962],
        [-123.139533996582, 49.2754783630372],
        [-123.138862609863, 49.2746009826661],
        [-123.13924407959, 49.2743682861328],
        [-123.139701843262, 49.2737617492676],
        [-123.140037536621, 49.2731437683105],
        [-123.139877319336, 49.2724990844727],
        [-123.139221191406, 49.2713890075684],
        [-123.137969970703, 49.2709922790527],
        [-123.137519836426, 49.2704620361329],
        [-123.136978149414, 49.2707443237305],
        [-123.137771606445, 49.2719841003418],
        [-123.137451171875, 49.2726974487305],
        [-123.136932373047, 49.2730293273926],
        [-123.136001586914, 49.2732200622559],
        [-123.135772705078, 49.2731666564942],
        [-123.134719848633, 49.2730560302734],
        [-123.13444519043, 49.272777557373],
        [-123.134162902832, 49.272777557373],
        [-123.133888244629, 49.2724990844727],
        [-123.133613586426, 49.2724990844727],
        [-123.133056640625, 49.2719459533691],
        [-123.132774353027, 49.2719459533691],
        [-123.132499694824, 49.2716674804687],
        [-123.132225036621, 49.2716674804687],
        [-123.131088256836, 49.2709159851075],
        [-123.130676269531, 49.2703628540039],
        [-123.130340576172, 49.2699737548828],
        [-123.129943847656, 49.2693901062012],
        [-123.130950927734, 49.2687568664551],
        [-123.131202697754, 49.2682991027833],
        [-123.129745483398, 49.2683982849122],
        [-123.128265380859, 49.2684593200685],
        [-123.125068664551, 49.268138885498],
        [-123.124244689941, 49.2682495117188],
        [-123.123764038086, 49.2686309814453],
        [-123.122451782227, 49.2684974670411],
        [-123.12215423584, 49.2685813903809],
        [-123.121963500977, 49.2684440612794],
        [-123.121475219727, 49.2683525085449],
        [-123.121040344238, 49.2678375244142],
        [-123.120292663574, 49.2678604125977],
        [-123.119552612305, 49.2680130004884],
        [-123.119407653809, 49.2690238952637],
        [-123.118812561035, 49.26957321167],
        [-123.118255615234, 49.2694053649903],
        [-123.116676330566, 49.2705841064453],
        [-123.114395141602, 49.2716064453126],
        [-123.111030578613, 49.2721900939941],
        [-123.108253479004, 49.2721481323243],
        [-123.105758666992, 49.2724113464356],
        [-123.103538513184, 49.2725219726563],
        [-123.102821350098, 49.2729187011719],
        [-123.10294342041, 49.2738075256349],
        [-123.10368347168, 49.2741470336913],
        [-123.103851318359, 49.2739257812501],
        [-123.104156494141, 49.2744598388672],
        [-123.103958129883, 49.274829864502],
        [-123.103775024414, 49.2750511169434],
        [-123.10368347168, 49.2751579284669],
        [-123.104072570801, 49.2756614685059],
        [-123.106002807617, 49.2757720947266],
        [-123.107490539551, 49.2760543823242],
        [-123.107696533203, 49.2759361267091],
        [-123.108276367187, 49.2757797241212],
        [-123.109474182129, 49.2747497558594],
        [-123.111206054688, 49.274211883545],
        [-123.113227844238, 49.2736053466797],
        [-123.114753723144, 49.2726631164552],
        [-123.115440368652, 49.2725486755371],
        [-123.116432189941, 49.2733688354493],
        [-123.116981506348, 49.273567199707],
        [-123.118316650391, 49.2732238769531],
        [-123.119239807129, 49.2724304199218],
        [-123.120109558105, 49.2713279724122],
        [-123.121238708496, 49.2711639404297],
        [-123.121864318848, 49.2711944580079],
        [-123.12230682373, 49.2713050842286],
        [-123.123512268066, 49.2719154357911],
        [-123.124633789062, 49.2719802856446],
        [-123.125679016113, 49.2715148925781],
        [-123.125961303711, 49.2712516784668],
        [-123.126220703125, 49.2710113525391],
        [-123.126625061035, 49.2708816528321],
        [-123.12712097168, 49.2708320617676],
        [-123.127639770508, 49.2712249755861],
        [-123.127906799316, 49.2712211608887],
        [-123.129623413086, 49.2717666625977],
        [-123.130264282227, 49.2719688415527],
        [-123.133499145508, 49.2739753723145],
        [-123.133888244629, 49.2741661071778],
        [-123.134162902832, 49.2744445800782],
        [-123.13444519043, 49.2744445800782],
        [-123.13500213623, 49.275001525879],
        [-123.135185241699, 49.2756004333496],
        [-123.135749816894, 49.2764625549317],
        [-123.136871337891, 49.2773132324219],
        [-123.137054443359, 49.2773971557617],
        [-123.138031005859, 49.2778511047364],
        [-123.139335632324, 49.2789535522461],
        [-123.139381408691, 49.2793083190919],
        [-123.13948059082, 49.2794265747071],
        [-123.139656066894, 49.2796783447265],
        [-123.13981628418, 49.2799491882325],
        [-123.140113830566, 49.2801399230958],
        [-123.14022064209, 49.2804527282715],
        [-123.14030456543, 49.2807006835938],
        [-123.140327453613, 49.2808609008789],
        [-123.140525817871, 49.2810821533204],
        [-123.140800476074, 49.2812843322755],
        [-123.141334533691, 49.2818031311036],
        [-123.141883850098, 49.2822952270509],
        [-123.142486572266, 49.2826385498047],
        [-123.142959594727, 49.2835235595703],
        [-123.143280029297, 49.2837028503419],
        [-123.144187927246, 49.2842788696289],
        [-123.14412689209, 49.2851219177246],
        [-123.143928527832, 49.2857971191406],
        [-123.143424987793, 49.2864685058594],
        [-123.143409729004, 49.2871398925781],
        [-123.143600463867, 49.2877502441406],
        [-123.143981933594, 49.2883186340333],
        [-123.144416809082, 49.2888221740723],
        [-123.144897460937, 49.2891883850098],
        [-123.145385742187, 49.2895469665527],
        [-123.145896911621, 49.2898712158204],
        [-123.146484375, 49.2901268005371],
        [-123.146850585938, 49.2903289794923],
        [-123.147445678711, 49.2907943725586],
        [-123.147888183594, 49.29109954834],
        [-123.148323059082, 49.291374206543],
        [-123.148750305176, 49.2916603088379],
        [-123.14917755127, 49.2919464111328],
        [-123.149589538574, 49.2922477722169],
        [-123.149955749512, 49.2925987243652],
        [-123.150093078613, 49.29301071167],
        [-123.150161743164, 49.2937469482423],
        [-123.150634765625, 49.2951736450195],
        [-123.151298522949, 49.2952766418457],
        [-123.152160644531, 49.2950897216798],
        [-123.152809143066, 49.2951965332032],
        [-123.153213500977, 49.2959175109864],
        [-123.153495788574, 49.2964324951173],
        [-123.153884887695, 49.2969551086426],
        [-123.154754638672, 49.2973937988282],
        [-123.155319213867, 49.2974433898926],
        [-123.156242370605, 49.2974319458008],
        [-123.156936645508, 49.2974853515626],
        [-123.157653808594, 49.2976989746094],
        [-123.158172607422, 49.2980766296386],
        [-123.158241271973, 49.2985954284669],
        [-123.158584594727, 49.2991561889648],
        [-123.158920288086, 49.2997436523438],
        [-123.159034729004, 49.3003578186036],
        [-123.159088134766, 49.3009147644044],
        [-123.158645629883, 49.3013076782227],
        [-123.158493041992, 49.3014640808107],
        [-123.158233642578, 49.3016891479493],
        [-123.157829284668, 49.3019561767579],
        [-123.157470703125, 49.3021583557129],
        [-123.15705871582, 49.3025741577148],
        [-123.15665435791, 49.303050994873],
        [-123.15673828125, 49.3036270141602],
        [-123.156753540039, 49.3041915893555],
        [-123.156898498535, 49.304786682129],
        [-123.156913757324, 49.3054199218749],
        [-123.15682220459, 49.306064605713],
        [-123.156707763672, 49.306713104248],
        [-123.156639099121, 49.3073654174805],
        [-123.156448364258, 49.3079566955568],
        [-123.156089782715, 49.3084373474121],
        [-123.155639648438, 49.3087959289551],
        [-123.155113220215, 49.3089447021485],
        [-123.154647827148, 49.3090858459474],
        [-123.154350280762, 49.3092689514161],
        [-123.154052734375, 49.3094177246095],
        [-123.15372467041, 49.3095016479493],
        [-123.153457641602, 49.3096389770508],
        [-123.153213500977, 49.3098297119141],
        [-123.152984619141, 49.3102035522461],
        [-123.15274810791, 49.3108787536621],
        [-123.152359008789, 49.3115386962891],
        [-123.151763916016, 49.3120422363282],
        [-123.151000976562, 49.3123474121094],
        [-123.150276184082, 49.312671661377],
        [-123.149528503418, 49.3127746582032],
        [-123.148811340332, 49.3128356933595],
        [-123.148132324219, 49.3130912780763],
        [-123.147483825684, 49.3131103515625],
        [-123.146911621094, 49.3133049011232],
        [-123.146324157715, 49.3134307861328],
        [-123.145698547363, 49.3134803771974],
        [-123.145042419434, 49.3136901855469],
        [-123.141975402832, 49.3141212463379],
        [-123.14111328125, 49.3139457702637],
        [-123.139778137207, 49.3133888244629],
        [-123.139442443848, 49.3127784729004],
        [-123.139167785644, 49.3125],
        [-123.138999938965, 49.3122215270996],
        [-123.138778686523, 49.3120422363282],
        [-123.138168334961, 49.3115539550781],
        [-123.138053894043, 49.3113899230958],
        [-123.13777923584, 49.3111114501954],
        [-123.13744354248, 49.310718536377],
        [-123.136703491211, 49.3102569580078],
        [-123.136924743652, 49.3098182678223],
        [-123.136627197266, 49.3093872070314],
        [-123.136215209961, 49.3090705871583],
        [-123.135864257812, 49.3086547851564],
        [-123.135665893555, 49.308032989502],
        [-123.135360717773, 49.3073310852051],
        [-123.135101318359, 49.3071174621583],
        [-123.134887695312, 49.3071098327637],
        [-123.134132385254, 49.3066864013672],
        [-123.13370513916, 49.3063926696778],
        [-123.133255004883, 49.3061256408691],
        [-123.1328125, 49.3058509826661],
        [-123.132453918457, 49.3054962158204],
        [-123.132164001465, 49.3050918579102],
        [-123.131813049316, 49.3047447204591],
        [-123.131416320801, 49.3044319152832],
        [-123.13102722168, 49.3040924072265],
        [-123.130577087402, 49.3037338256836],
        [-123.130142211914, 49.3033409118653],
        [-123.129669189453, 49.3029937744141],
        [-123.129150390625, 49.3028068542482],
        [-123.12858581543, 49.3027458190919],
        [-123.128028869629, 49.3026161193849],
        [-123.127136230469, 49.3025741577148],
        [-123.126655578613, 49.3029098510743],
        [-123.126396179199, 49.3023262023926],
        [-123.125915527344, 49.3021278381348],
        [-123.125495910645, 49.3018455505372],
        [-123.124992370605, 49.3016319274903],
        [-123.124519348145, 49.3013534545899],
        [-123.123840332031, 49.301296234131],
        [-123.123573303223, 49.3008117675782],
        [-123.123046875, 49.300594329834],
        [-123.122467041016, 49.3004760742188],
        [-123.121940612793, 49.3002738952637],
        [-123.121429443359, 49.3000793457031],
        [-123.120895385742, 49.3000068664551],
        [-123.120346069336, 49.2999992370605],
        [-123.119781494141, 49.2999420166016],
        [-123.11897277832, 49.2999496459961],
        [-123.118690490723, 49.3000640869142],
        [-123.118240356445, 49.3002738952637],
        [-123.117660522461, 49.3010902404786],
        [-123.11693572998, 49.3009338378907],
        [-123.116767883301, 49.2997703552247],
        [-123.116790771484, 49.2993354797364],
        [-123.116966247559, 49.2988891601564],
        [-123.11727142334, 49.2985191345215],
        [-123.117706298828, 49.2982559204102],
        [-123.118179321289, 49.2980155944825],
        [-123.118743896484, 49.2979621887208],
        [-123.119323730469, 49.2980270385742],
        [-123.119918823242, 49.2980537414552],
        [-123.120506286621, 49.2980461120607],
        [-123.121215820312, 49.2981796264648],
        [-123.121978759766, 49.2983207702637],
        [-123.122833251953, 49.2979774475098],
        [-123.123252868652, 49.2979583740236],
        [-123.123756408691, 49.2981643676759],
        [-123.126029968262, 49.2980613708497],
        [-123.127197265625, 49.2966384887696],
        [-123.128227233887, 49.2960510253907],
        [-123.129043579102, 49.2962188720704],
        [-123.129669189453, 49.297203063965],
        [-123.130218505859, 49.2978515625],
        [-123.131126403809, 49.2979736328126],
        [-123.132110595703, 49.2970581054688],
        [-123.132659912109, 49.2968330383301],
        [-123.133331298828, 49.2969627380371],
        [-123.133651733398, 49.2970504760742],
        [-123.134033203125, 49.2970199584962],
        [-123.135673522949, 49.2959594726563],
        [-123.135261535644, 49.2955551147461],
        [-123.133499145508, 49.2950820922853],
        [-123.132736206055, 49.2946929931642],
        [-123.131332397461, 49.2937698364259],
        [-123.130905151367, 49.2934913635255],
        [-123.130477905273, 49.2930641174318],
        [-123.129737854004, 49.2934608459474],
        [-123.12907409668, 49.2934951782227],
        [-123.128715515137, 49.2932929992677],
        [-123.12801361084, 49.2929992675782],
        [-123.127731323242, 49.2921714782714],
        [-123.126708984375, 49.2910079956055],
        [-123.124687194824, 49.2905769348145],
        [-123.123306274414, 49.2910194396972],
        [-123.122444152832, 49.2910652160645],
        [-123.121704101562, 49.2910575866699],
        [-123.121345520019, 49.290340423584],
        [-123.120491027832, 49.2903442382814],
        [-123.119964599609, 49.2900428771974],
        [-123.119316101074, 49.2897109985352],
        [-123.118675231934, 49.2896423339844],
        [-123.118103027344, 49.2895202636719],
        [-123.11742401123, 49.2896766662599],
        [-123.117095947266, 49.2896156311036],
        [-123.116622924805, 49.2896575927734],
        [-123.116165161133, 49.2898712158204],
        [-123.116065979004, 49.2898902893066],
        [-123.115348815918, 49.2899589538575],
        [-123.114479064941, 49.2898826599121],
        [-123.114486694336, 49.2897682189942],
        [-123.11408996582, 49.2895240783691],
        [-123.114364624023, 49.2894096374512],
        [-123.115585327148, 49.2888755798341],
        [-123.115921020508, 49.2883491516113],
        [-123.113639831543, 49.2885513305665],
        [-123.113182067871, 49.2888183593751],
        [-123.112510681152, 49.2891960144044],
        [-123.11222076416, 49.2891654968262],
        [-123.11164855957, 49.2891654968262],
        [-123.111099243164, 49.2892036437989],
        [-123.110618591309, 49.2893486022949],
        [-123.110198974609, 49.2893028259277],
        [-123.109771728516, 49.2891540527345],
        [-123.109825134277, 49.2883110046388],
        [-123.111480712891, 49.2880172729493],
        [-123.111877441406, 49.2876167297363],
        [-123.111869812012, 49.2874908447265],
        [-123.109558105469, 49.287654876709],
        [-123.108657836914, 49.2872657775879],
        [-123.108200073242, 49.2869873046875],
        [-123.107627868652, 49.2868690490723],
        [-123.107124328613, 49.2866592407227],
        [-123.106636047363, 49.2864341735841],
        [-123.106094360352, 49.2863502502442],
        [-123.105506896973, 49.2863426208497],
        [-123.105056762695, 49.2862510681152],
        [-123.104446411133, 49.2861099243165],
        [-123.103660583496, 49.2859420776368],
        [-123.102272033691, 49.2854461669922],
        [-123.100799560547, 49.2857818603515],
        [-123.100021362305, 49.285873413086],
        [-123.099884033203, 49.2862854003907],
        [-123.099723815918, 49.2867279052734],
        [-123.100090026855, 49.2871208190919],
        [-123.09969329834, 49.2873229980469],
        [-123.099174499512, 49.2872123718261],
        [-123.098526000977, 49.2864837646486],
        [-123.098258972168, 49.2856178283692],
        [-123.097816467285, 49.2855644226075],
        [-123.095695495605, 49.2860603332519],
        [-123.095924377441, 49.2865028381348],
        [-123.097091674805, 49.287223815918],
        [-123.099464416504, 49.2877235412599],
        [-123.099967956543, 49.2879447937013],
        [-123.099517822266, 49.2886657714844],
        [-123.099327087402, 49.2894401550294],
        [-123.098739624023, 49.289608001709],
        [-123.096961975098, 49.2893943786622],
        [-123.096138000488, 49.2891769409181],
        [-123.094253540039, 49.2892265319825],
        [-123.09260559082, 49.2886199951173],
        [-123.092483520508, 49.2881813049316],
        [-123.092063903809, 49.2883758544922],
        [-123.092216491699, 49.2888755798341],
        [-123.091812133789, 49.2894096374512],
        [-123.091499328613, 49.2894401550294],
        [-123.091110229492, 49.2894668579102],
        [-123.090797424316, 49.2891426086426],
        [-123.090545654297, 49.2890167236328],
        [-123.090209960938, 49.2891883850098],
        [-123.089698791504, 49.2891845703126],
        [-123.089508056641, 49.2887649536133],
        [-123.089447021484, 49.2885093688966],
        [-123.089958190918, 49.2870674133301],
        [-123.089767456055, 49.2857360839845],
        [-123.088233947754, 49.2857360839845],
        [-123.086868286133, 49.2854576110841],
        [-123.085327148438, 49.2849617004395],
        [-123.084884643555, 49.2852897644044],
        [-123.08519744873, 49.2858428955078],
        [-123.085021972656, 49.2859382629395],
        [-123.084571838379, 49.2864570617676],
        [-123.084289550781, 49.2863655090333],
        [-123.084182739258, 49.2861709594727],
        [-123.084144592285, 49.2857513427736],
        [-123.084297180176, 49.2843704223633],
        [-123.084053039551, 49.2839851379395],
        [-123.08332824707, 49.2840423583986],
        [-123.08243560791, 49.2836494445801],
        [-123.081596374512, 49.2840232849121],
        [-123.081298828125, 49.2847137451172],
        [-123.081611633301, 49.2852287292481],
        [-123.08226776123, 49.2855453491212],
        [-123.082489013672, 49.2862663269043],
        [-123.082084655762, 49.2867317199708],
        [-123.081344604492, 49.2864875793457],
        [-123.081329345703, 49.2869529724122],
        [-123.081314086914, 49.2872734069824],
        [-123.080703735352, 49.2872161865235],
        [-123.080596923828, 49.2867240905762],
        [-123.08056640625, 49.2862281799318],
        [-123.080375671387, 49.2857360839845],
        [-123.080184936523, 49.2855682373048],
        [-123.079765319824, 49.2856864929199],
        [-123.079154968262, 49.2858505249024],
        [-123.078712463379, 49.2863502502442],
        [-123.079071044922, 49.2865715026856],
        [-123.079605102539, 49.2869606018068],
        [-123.079498291016, 49.2872352600098],
        [-123.079139709473, 49.2872390747071],
        [-123.078353881836, 49.2871818542482],
        [-123.077850341797, 49.2874069213868],
        [-123.077491760254, 49.288013458252],
        [-123.077583312988, 49.2886734008789],
        [-123.077224731445, 49.2891120910644],
        [-123.076942443848, 49.2894439697266],
        [-123.076614379883, 49.289836883545],
        [-123.075866699219, 49.2898864746094],
        [-123.07536315918, 49.2895584106446],
        [-123.074584960938, 49.289390563965],
        [-123.073997497559, 49.2891159057618],
        [-123.073585510254, 49.2890625],
        [-123.073257446289, 49.2891693115236],
        [-123.07243347168, 49.2890777587891],
        [-123.071983337402, 49.2888946533203],
        [-123.071365356445, 49.2886734008789],
        [-123.070579528809, 49.28861618042],
        [-123.070106506348, 49.2892227172852],
        [-123.069641113281, 49.2895584106446],
        [-123.069442749023, 49.289722442627],
        [-123.068893432617, 49.289722442627],
        [-123.068702697754, 49.2891693115236],
        [-123.068450927734, 49.2888946533203],
        [-123.068061828613, 49.2885208129883],
        [-123.068077087402, 49.2881546020508],
        [-123.068748474121, 49.2873077392578],
        [-123.0693359375, 49.2869415283203],
        [-123.069564819336, 49.2863807678223],
        [-123.069114685059, 49.285717010498],
        [-123.068428039551, 49.28564453125],
        [-123.068115234375, 49.286003112793],
        [-123.067680358887, 49.286319732666],
        [-123.067321777344, 49.2871398925781],
        [-123.067199707031, 49.2876167297363],
        [-123.06640625, 49.2875480651857],
        [-123.065948486328, 49.2875709533693],
        [-123.065101623535, 49.2872161865235],
        [-123.06494140625, 49.2866401672363],
        [-123.064529418945, 49.2868347167969],
        [-123.064430236816, 49.2876014709473],
        [-123.064331054687, 49.2878532409669],
        [-123.063995361328, 49.2882080078126],
        [-123.063316345215, 49.28861618042],
        [-123.062576293945, 49.2883415222169],
        [-123.062217712402, 49.2888374328614],
        [-123.061416625977, 49.2898406982422],
        [-123.06086730957, 49.2903747558594],
        [-123.06005859375, 49.2905464172364],
        [-123.059555053711, 49.2910461425782],
        [-123.059127807617, 49.291446685791],
        [-123.05835723877, 49.2916679382325],
        [-123.057792663574, 49.2919158935547],
        [-123.057334899902, 49.2920799255372],
        [-123.056663513184, 49.2922248840332],
        [-123.055992126465, 49.2922897338867],
        [-123.055511474609, 49.29252243042],
        [-123.054969787598, 49.2926750183106],
        [-123.05436706543, 49.2929191589357],
        [-123.053886413574, 49.2932052612306],
        [-123.053062438965, 49.2933158874511],
        [-123.052612304688, 49.2935600280762],
        [-123.052169799805, 49.2936668395997],
        [-123.051300048828, 49.2936744689943],
        [-123.051025390625, 49.2941665649413],
        [-123.050491333008, 49.2941703796387],
        [-123.050117492676, 49.2941665649413],
        [-123.049743652344, 49.2939491271973],
        [-123.049377441406, 49.2934494018555],
        [-123.048851013184, 49.2936820983888],
        [-123.047492980957, 49.2940406799318],
        [-123.046798706055, 49.2939529418946],
        [-123.046310424805, 49.2936096191406],
        [-123.046112060547, 49.2933540344238],
        [-123.045875549316, 49.2932167053223],
        [-123.045639038086, 49.2927360534667],
        [-123.045188903809, 49.2925872802736],
        [-123.044525146484, 49.2925643920899],
        [-123.043495178223, 49.2924842834473],
        [-123.043067932129, 49.292278289795],
        [-123.042762756348, 49.292335510254],
        [-123.042198181152, 49.2923851013184],
        [-123.041732788086, 49.2924423217775],
        [-123.041198730469, 49.2924118041993],
        [-123.040885925293, 49.2923316955568],
        [-123.040077209473, 49.2917022705078],
        [-123.039474487305, 49.2910385131837],
        [-123.036735534668, 49.2909545898438],
        [-123.035194396973, 49.2907905578614],
        [-123.034576416016, 49.2903747558594],
        [-123.0341796875, 49.2901916503906],
        [-123.033981323242, 49.290470123291],
        [-123.03434753418, 49.291000366211],
        [-123.033584594727, 49.2911567687989],
        [-123.032485961914, 49.2912063598633],
        [-123.032577514648, 49.290771484375],
        [-123.032249450684, 49.2905578613281],
        [-123.030921936035, 49.2912254333496],
        [-123.029235839844, 49.2921676635743],
        [-123.027862548828, 49.2925453186035],
        [-123.027160644531, 49.2927017211914],
        [-123.0234375, 49.2931976318361],
        [-123.023498535156, 49.2899017333985],
        [-123.023422241211, 49.2899246215821],
        [-123.023429870605, 49.289764404297],
        [-123.023422241211, 49.2893562316895],
        [-123.023445129395, 49.2887802124025],
        [-123.023460388184, 49.2883949279785],
        [-123.023429870605, 49.287712097168],
        [-123.0234375, 49.2875518798828],
        [-123.023460388184, 49.286693572998],
        [-123.023460388184, 49.28564453125],
        [-123.023475646973, 49.284740447998],
        [-123.023475646973, 49.2838897705078],
        [-123.023483276367, 49.2829704284669],
        [-123.023483276367, 49.2820434570313],
        [-123.023498535156, 49.2812271118164],
        [-123.023506164551, 49.2802200317384],
        [-123.023506164551, 49.2794227600098],
        [-123.023506164551, 49.2791137695312],
        [-123.023513793945, 49.2785110473634],
        [-123.023529052734, 49.2783088684082],
        [-123.02352142334, 49.2775993347169],
        [-123.023544311523, 49.2774734497071],
        [-123.023536682129, 49.2767333984376],
        [-123.023536682129, 49.2765274047852],
        [-123.023559570312, 49.2758140563965],
        [-123.023544311523, 49.2749900817872],
        [-123.023551940918, 49.2748985290528],
        [-123.023536682129, 49.2741851806641],
        [-123.02352142334, 49.2739753723145],
        [-123.023559570312, 49.2731323242189],
        [-123.023544311523, 49.2721977233887],
        [-123.023551940918, 49.2713851928712],
        [-123.023574829102, 49.2703742980958],
        [-123.023582458496, 49.2696304321289],
        [-123.023597717285, 49.2685241699219],
        [-123.02360534668, 49.2683525085449],
        [-123.023597717285, 49.2660942077637],
        [-123.023612976074, 49.2657585144044],
        [-123.023651123047, 49.2635192871094],
        [-123.023666381836, 49.2624702453613],
        [-123.02367401123, 49.2620964050293],
        [-123.02367401123, 49.2616806030274],
        [-123.02367401123, 49.2614173889161],
        [-123.023696899414, 49.261085510254],
        [-123.023681640625, 49.260555267334],
        [-123.023681640625, 49.2601318359376],
        [-123.023704528809, 49.2599143981935],
        [-123.023689270019, 49.259220123291],
        [-123.023704528809, 49.25834274292],
        [-123.023689270019, 49.2573318481446],
        [-123.023712158203, 49.2564353942872],
        [-123.023727416992, 49.2555694580079],
        [-123.023704528809, 49.2553482055665],
        [-123.023719787598, 49.2549324035646],
        [-123.023742675781, 49.2542304992676],
        [-123.023727416992, 49.2533302307129],
        [-123.023727416992, 49.2524757385255],
        [-123.023742675781, 49.2515411376954],
        [-123.023742675781, 49.2513542175292],
        [-123.023750305176, 49.2506980895997],
        [-123.023742675781, 49.2505073547364],
        [-123.023727416992, 49.2500267028809],
        [-123.023719787598, 49.2497863769531],
        [-123.023643493652, 49.2489891052247],
        [-123.023651123047, 49.2480125427247],
        [-123.023689270019, 49.2471275329591],
        [-123.02367401123, 49.2461967468261],
        [-123.023651123047, 49.245361328125],
        [-123.023681640625, 49.2448921203613],
        [-123.024055480957, 49.2446403503419],
        [-123.023506164551, 49.2430114746095],
        [-123.023536682129, 49.2426414489746],
        [-123.023513793945, 49.2420578002931],
        [-123.023506164551, 49.2415618896484],
        [-123.02352142334, 49.2410202026367],
        [-123.023483276367, 49.2404022216797],
        [-123.02352142334, 49.2396202087403],
        [-123.023529052734, 49.2374458312988],
        [-123.023506164551, 49.2369651794434],
        [-123.023544311523, 49.236213684082],
        [-123.023544311523, 49.2348175048829],
        [-123.023529052734, 49.2346534729004],
        [-123.023513793945, 49.2344932556153],
        [-123.023460388184, 49.2323417663574],
        [-123.023361206055, 49.229232788086],
        [-123.023330688476, 49.2279701232911],
        [-123.023323059082, 49.2262992858887],
        [-123.023315429687, 49.225154876709],
        [-123.023323059082, 49.2226867675781],
        [-123.023300170898, 49.2222938537598],
        [-123.023307800293, 49.2218551635743],
        [-123.023315429687, 49.2209777832031],
        [-123.023323059082, 49.220085144043],
        [-123.023315429687, 49.2192192077637],
        [-123.023284912109, 49.2183113098146],
        [-123.023307800293, 49.2177162170411],
        [-123.023262023926, 49.2165565490723],
        [-123.023254394531, 49.215675354004],
        [-123.023231506348, 49.2149047851563],
        [-123.023231506348, 49.214656829834],
        [-123.023200988769, 49.2136611938478],
        [-123.023239135742, 49.2126731872559],
        [-123.023178100586, 49.2119293212892],
        [-123.023239135742, 49.2118492126466],
        [-123.023422241211, 49.2091484069825],
        [-123.023307800293, 49.208766937256],
        [-123.023262023926, 49.2086257934571],
        [-123.023254394531, 49.208065032959],
        [-123.023200988769, 49.2067909240723],
        [-123.023292541504, 49.2052993774414],
        [-123.023094177246, 49.2042694091797],
        [-123.023101806641, 49.2041206359864],
        [-123.023101806641, 49.2039833068848],
        [-123.023139953613, 49.2024688720703],
        [-123.023132324219, 49.2010421752931],
        [-123.023124694824, 49.2009239196778],
        [-123.023002624512, 49.1995315551759],
        [-123.023155212402, 49.1980628967286],
        [-123.036399841309, 49.2026596069336],
        [-123.037643432617, 49.2030944824219],
        [-123.079162597656, 49.2047767639161],
        [-123.079216003418, 49.2047805786133],
        [-123.109443664551, 49.202205657959],
        [-123.118354797363, 49.2001953125],
        [-123.124755859375, 49.1987495422363],
        [-123.133140563965, 49.1991043090821],
      ],
    ],
  },
}

export const richmondGeoJson = {
  type: 'Feature',
  geometry: {
    type: 'Polygon',
    coordinates: [
      [
        [-122.957168579102, 49.1756057739258],
        [-122.957336425781, 49.1754455566407],
        [-122.957885742187, 49.1754455566407],
        [-122.960662841797, 49.1735000610352],
        [-122.961219787598, 49.1729431152344],
        [-122.961219787598, 49.1726684570312],
        [-122.963447570801, 49.1704444885255],
        [-122.963722229004, 49.1698913574219],
        [-122.965156555176, 49.1686439514161],
        [-122.968688964844, 49.1679458618164],
        [-122.969291687012, 49.1679420471193],
        [-122.969871520996, 49.167896270752],
        [-122.970413208008, 49.1677131652833],
        [-122.970993041992, 49.1676139831542],
        [-122.971504211426, 49.1673851013185],
        [-122.971992492676, 49.1671218872071],
        [-122.972503662109, 49.1668968200684],
        [-122.973075866699, 49.1668357849122],
        [-122.973655700684, 49.1667785644531],
        [-122.974235534668, 49.1665916442872],
        [-122.97484588623, 49.1664276123047],
        [-122.975463867188, 49.1663093566895],
        [-122.97607421875, 49.1662788391113],
        [-122.97664642334, 49.1662254333497],
        [-122.977104187012, 49.1660308837891],
        [-122.977584838867, 49.1658821105958],
        [-122.978057861328, 49.1657028198243],
        [-122.978507995605, 49.1654891967773],
        [-122.97966003418, 49.1656036376954],
        [-122.980834960938, 49.1656455993653],
        [-122.981536865234, 49.1656494140625],
        [-122.981887817383, 49.1657257080079],
        [-122.982475280762, 49.1655311584473],
        [-122.98282623291, 49.1655006408693],
        [-122.9833984375, 49.1652870178224],
        [-122.983551025391, 49.1653060913087],
        [-122.983772277832, 49.1651344299316],
        [-122.98405456543, 49.1649017333984],
        [-122.984565734863, 49.1647453308107],
        [-122.985069274902, 49.1644744873046],
        [-122.985748291016, 49.1634902954103],
        [-122.986961364746, 49.1632041931153],
        [-122.987564086914, 49.1631088256837],
        [-122.988136291504, 49.1629371643067],
        [-122.98868560791, 49.1627388000489],
        [-122.9892578125, 49.1626205444336],
        [-122.989768981934, 49.1624488830568],
        [-122.990348815918, 49.1623916625977],
        [-122.990928649902, 49.1623268127441],
        [-122.991424560547, 49.1621360778809],
        [-122.991928100586, 49.1619682312012],
        [-122.99242401123, 49.1618270874023],
        [-122.99291229248, 49.1616859436036],
        [-122.993423461914, 49.1615867614747],
        [-122.993949890137, 49.1615600585939],
        [-122.994552612305, 49.1615562438965],
        [-122.996223449707, 49.1618347167969],
        [-122.997886657715, 49.1618347167969],
        [-122.999000549316, 49.1612777709961],
        [-122.999557495117, 49.1607246398927],
        [-123.0, 49.1602783203124],
        [-123.000564575195, 49.1595039367676],
        [-123.002136230469, 49.1582183837891],
        [-123.003379821777, 49.1567802429199],
        [-123.006088256836, 49.1550598144532],
        [-123.009605407715, 49.1542243957521],
        [-123.016380310059, 49.1533432006837],
        [-123.023918151855, 49.1521377563477],
        [-123.027709960937, 49.1517143249512],
        [-123.030296325684, 49.1511535644531],
        [-123.032974243164, 49.1507720947266],
        [-123.037261962891, 49.1496772766114],
        [-123.04182434082, 49.1482124328613],
        [-123.045631408691, 49.1465873718263],
        [-123.049644470215, 49.1447410583496],
        [-123.053482055664, 49.1419792175294],
        [-123.057411193848, 49.1380462646485],
        [-123.061950683594, 49.1342048645021],
        [-123.066070556641, 49.1312713623048],
        [-123.0673828125, 49.1303787231445],
        [-123.067665100098, 49.129711151123],
        [-123.074325561523, 49.1255416870118],
        [-123.081344604492, 49.1228828430176],
        [-123.082427978516, 49.1225013732911],
        [-123.083717346191, 49.1225471496583],
        [-123.084419250488, 49.1229972839355],
        [-123.084083557129, 49.1237106323242],
        [-123.083778381348, 49.1242256164551],
        [-123.084777832031, 49.1255378723145],
        [-123.086196899414, 49.1256675720215],
        [-123.087509155273, 49.1252059936523],
        [-123.087608337402, 49.1245079040527],
        [-123.08708190918, 49.1241073608399],
        [-123.086364746094, 49.1231002807617],
        [-123.086265563965, 49.1223373413086],
        [-123.08846282959, 49.1210556030274],
        [-123.090728759766, 49.1211814880372],
        [-123.092918395996, 49.1205253601074],
        [-123.094367980957, 49.1199340820313],
        [-123.094802856445, 49.1197509765626],
        [-123.095252990723, 49.1196060180665],
        [-123.095687866211, 49.1194267272949],
        [-123.096099853516, 49.1192169189454],
        [-123.096618652344, 49.1191253662109],
        [-123.097091674805, 49.1189193725586],
        [-123.097595214844, 49.1187820434571],
        [-123.098083496094, 49.1186218261719],
        [-123.098587036133, 49.1184883117676],
        [-123.099067687988, 49.1183242797852],
        [-123.099533081055, 49.1181335449219],
        [-123.10001373291, 49.1180038452149],
        [-123.100425720215, 49.1177940368653],
        [-123.100845336914, 49.1176147460938],
        [-123.101272583008, 49.1174507141113],
        [-123.10164642334, 49.1172447204591],
        [-123.102073669434, 49.1170883178712],
        [-123.102508544922, 49.1169242858887],
        [-123.102920532227, 49.1167335510254],
        [-123.103401184082, 49.1166458129883],
        [-123.103858947754, 49.1165161132813],
        [-123.104293823242, 49.1163673400879],
        [-123.104698181152, 49.1161727905275],
        [-123.105163574219, 49.1160850524903],
        [-123.105590820312, 49.1159362792969],
        [-123.106018066406, 49.1158180236817],
        [-123.106407165527, 49.1156730651856],
        [-123.106781005859, 49.1155624389649],
        [-123.107139587402, 49.1154670715333],
        [-123.107414245605, 49.1153221130372],
        [-123.107719421387, 49.1152496337892],
        [-123.107963562012, 49.1150817871094],
        [-123.108215332031, 49.1150131225587],
        [-123.108474731445, 49.1149406433107],
        [-123.108642578125, 49.1147537231445],
        [-123.10888671875, 49.1147232055664],
        [-123.113662719727, 49.1130523681641],
        [-123.114082336426, 49.1130294799805],
        [-123.114463806152, 49.1129035949707],
        [-123.114837646484, 49.112762451172],
        [-123.115203857422, 49.112590789795],
        [-123.115623474121, 49.1124801635743],
        [-123.116020202637, 49.1123313903809],
        [-123.116470336914, 49.1122398376465],
        [-123.116882324219, 49.1122016906739],
        [-123.117370605469, 49.112060546875],
        [-123.117942810059, 49.1119346618652],
        [-123.118286132812, 49.111915588379],
        [-123.118927001953, 49.1119346618652],
        [-123.119338989258, 49.1115303039552],
        [-123.119956970215, 49.1114654541016],
        [-123.120544433594, 49.1113891601564],
        [-123.121147155762, 49.111110687256],
        [-123.121742248535, 49.1111335754396],
        [-123.122047424316, 49.1110153198243],
        [-123.122505187988, 49.1108589172364],
        [-123.12321472168, 49.1109580993653],
        [-123.123512268066, 49.1110076904298],
        [-123.12425994873, 49.1109619140626],
        [-123.124794006348, 49.1109313964844],
        [-123.125671386719, 49.1106643676759],
        [-123.126190185547, 49.1101837158204],
        [-123.12671661377, 49.1100692749025],
        [-123.127418518066, 49.1100883483887],
        [-123.128204345703, 49.1099929809571],
        [-123.12882232666, 49.1100273132324],
        [-123.129348754883, 49.1100006103516],
        [-123.130043029785, 49.1099395751953],
        [-123.130393981934, 49.1099433898926],
        [-123.130821228027, 49.1099281311036],
        [-123.131797790527, 49.1099281311036],
        [-123.132385253906, 49.110034942627],
        [-123.13298034668, 49.1102409362793],
        [-123.13338470459, 49.1101570129396],
        [-123.133949279785, 49.1103134155273],
        [-123.134323120117, 49.1103019714356],
        [-123.135208129883, 49.1104888916015],
        [-123.135765075684, 49.1104316711426],
        [-123.136322021484, 49.1104049682618],
        [-123.13671875, 49.1104202270508],
        [-123.137229919434, 49.1104393005371],
        [-123.137924194336, 49.1105499267578],
        [-123.138366699219, 49.1106681823731],
        [-123.138885498047, 49.1107864379883],
        [-123.139617919922, 49.1108436584473],
        [-123.140487670898, 49.1106796264648],
        [-123.141120910645, 49.1109237670898],
        [-123.141754150391, 49.1108474731446],
        [-123.14241027832, 49.110652923584],
        [-123.14298248291, 49.1108245849611],
        [-123.143577575684, 49.1109504699708],
        [-123.14429473877, 49.1108322143556],
        [-123.144668579102, 49.1111488342286],
        [-123.145248413086, 49.1113586425782],
        [-123.145767211914, 49.1114768981934],
        [-123.146430969238, 49.1116714477539],
        [-123.146736145019, 49.1116905212403],
        [-123.147605895996, 49.1117210388185],
        [-123.148139953613, 49.1119499206543],
        [-123.148742675781, 49.1122245788575],
        [-123.149345397949, 49.1124000549317],
        [-123.149749755859, 49.1125755310059],
        [-123.150779724121, 49.1127243041993],
        [-123.152816772461, 49.11381149292],
        [-123.153907775879, 49.1148338317871],
        [-123.155281066895, 49.1151084899903],
        [-123.155830383301, 49.11527633667],
        [-123.156112670898, 49.11527633667],
        [-123.156387329102, 49.1155548095704],
        [-123.156837463379, 49.1158866882325],
        [-123.156944274902, 49.115779876709],
        [-123.157501220703, 49.1158332824708],
        [-123.157775878906, 49.1161117553712],
        [-123.15860748291, 49.1161117553712],
        [-123.159057617188, 49.116554260254],
        [-123.159942626953, 49.1165008544922],
        [-123.160217285156, 49.1168327331544],
        [-123.160499572754, 49.1173286437988],
        [-123.161026000977, 49.1174964904785],
        [-123.161140441895, 49.1174964904785],
        [-123.161163330078, 49.1182708740236],
        [-123.160186767578, 49.119369506836],
        [-123.160125732422, 49.1199226379396],
        [-123.160743713379, 49.1201438903809],
        [-123.161521911621, 49.1202583312989],
        [-123.162963867188, 49.1187705993653],
        [-123.164169311523, 49.1180572509766],
        [-123.164474487305, 49.1183319091797],
        [-123.164916992187, 49.1185531616211],
        [-123.16527557373, 49.1185531616211],
        [-123.165557861328, 49.1186103820801],
        [-123.166000366211, 49.1191635131837],
        [-123.166030883789, 49.1196594238281],
        [-123.166305541992, 49.1199340820313],
        [-123.166831970215, 49.1199913024902],
        [-123.167587280273, 49.119441986084],
        [-123.167778015137, 49.1194458007814],
        [-123.168334960938, 49.1199417114258],
        [-123.16869354248, 49.120548248291],
        [-123.169326782227, 49.1208229064942],
        [-123.170112609863, 49.1205520629884],
        [-123.171363830566, 49.1203308105469],
        [-123.172142028809, 49.1207733154298],
        [-123.17236328125, 49.1210479736328],
        [-123.172889709473, 49.1209411621095],
        [-123.173698425293, 49.1212730407714],
        [-123.174224853516, 49.1216583251954],
        [-123.175201416016, 49.1217155456543],
        [-123.175399780273, 49.1223220825195],
        [-123.174613952637, 49.1225433349609],
        [-123.17455291748, 49.1228752136231],
        [-123.175338745117, 49.1229858398438],
        [-123.175888061523, 49.1224937438965],
        [-123.17741394043, 49.1222229003907],
        [-123.179244995117, 49.1223869323732],
        [-123.180274963379, 49.1226119995118],
        [-123.18147277832, 49.1234931945801],
        [-123.18384552002, 49.1238250732423],
        [-123.186752319336, 49.1242103576661],
        [-123.1904296875, 49.1244354248047],
        [-123.191940307617, 49.1241912841798],
        [-123.192947387695, 49.1248970031738],
        [-123.194198608398, 49.1244468688965],
        [-123.195869445801, 49.1246452331544],
        [-123.197227478027, 49.1244087219239],
        [-123.19905090332, 49.1246528625489],
        [-123.20027923584, 49.125],
        [-123.200553894043, 49.1251106262207],
        [-123.200836181641, 49.1252784729003],
        [-123.203247070312, 49.1259994506836],
        [-123.20361328125, 49.1261100769044],
        [-123.203804016113, 49.1262207031251],
        [-123.203804016113, 49.1753578186036],
        [-123.203338623047, 49.1755218505861],
        [-123.20287322998, 49.1755523681641],
        [-123.202415466309, 49.1755142211915],
        [-123.20198059082, 49.1753654479981],
        [-123.201553344727, 49.1754035949708],
        [-123.201126098633, 49.1755256652833],
        [-123.200691223144, 49.1755523681641],
        [-123.200256347656, 49.1755561828614],
        [-123.199821472168, 49.1755561828614],
        [-123.199401855469, 49.1755828857422],
        [-123.199020385742, 49.1757011413575],
        [-123.198616027832, 49.175807952881],
        [-123.198150634766, 49.1758346557618],
        [-123.19766998291, 49.1758346557618],
        [-123.197189331055, 49.1758346557618],
        [-123.196708679199, 49.1758346557618],
        [-123.196220397949, 49.1758346557618],
        [-123.195732116699, 49.1758346557618],
        [-123.195236206055, 49.1758346557618],
        [-123.194732666016, 49.1758308410645],
        [-123.194236755371, 49.1757926940919],
        [-123.19376373291, 49.1756324768066],
        [-123.193244934082, 49.1755638122559],
        [-123.192710876465, 49.175506591797],
        [-123.192222595215, 49.1753273010254],
        [-123.191696166992, 49.1752433776855],
        [-123.191200256348, 49.1751022338868],
        [-123.190681457519, 49.1750144958497],
        [-123.190155029297, 49.1749458312989],
        [-123.189666748047, 49.1747703552247],
        [-123.18913269043, 49.1747245788574],
        [-123.188575744629, 49.1747207641603],
        [-123.188003540039, 49.1747131347657],
        [-123.187446594238, 49.1746635437013],
        [-123.186935424805, 49.1745185852051],
        [-123.186401367187, 49.1745834350585],
        [-123.185859680176, 49.1746902465821],
        [-123.185317993164, 49.1746711730956],
        [-123.184783935547, 49.1744918823243],
        [-123.184219360352, 49.174446105957],
        [-123.183647155762, 49.1744422912599],
        [-123.183074951172, 49.1744422912599],
        [-123.182510375977, 49.174446105957],
        [-123.18083190918, 49.174446105957],
        [-123.180282592773, 49.1744422912599],
        [-123.179740905762, 49.1744422912599],
        [-123.177665710449, 49.1747131347657],
        [-123.177131652832, 49.1747169494629],
        [-123.174736022949, 49.1746826171876],
        [-123.174133300781, 49.1745567321778],
        [-123.173774719238, 49.1744766235352],
        [-123.171073913574, 49.1743392944337],
        [-123.169883728027, 49.1742248535156],
        [-123.169281005859, 49.1745567321778],
        [-123.169174194336, 49.1746673583985],
        [-123.169059753418, 49.1747207641603],
        [-123.168533325195, 49.1747207641603],
        [-123.167999267578, 49.1747245788574],
        [-123.167388916016, 49.174602508545],
        [-123.164031982422, 49.1743087768555],
        [-123.158348083496, 49.174503326416],
        [-123.154281616211, 49.1753311157228],
        [-123.153060913086, 49.1757926940919],
        [-123.152587890625, 49.1758766174316],
        [-123.152153015137, 49.1760673522949],
        [-123.151672363281, 49.176155090332],
        [-123.151275634766, 49.1763610839844],
        [-123.150856018066, 49.1765098571778],
        [-123.150459289551, 49.1766700744629],
        [-123.150108337402, 49.1768379211426],
        [-123.149803161621, 49.1769981384277],
        [-123.149551391602, 49.1771812438965],
        [-123.149276733398, 49.1772956848146],
        [-123.145881652832, 49.1787719726562],
        [-123.145477294922, 49.1790161132812],
        [-123.144996643066, 49.1791954040528],
        [-123.14453125, 49.1794433593751],
        [-123.144058227539, 49.1796722412111],
        [-123.142532348633, 49.1808052062988],
        [-123.141731262207, 49.181453704834],
        [-123.140060424805, 49.1830596923829],
        [-123.139724731445, 49.1841659545898],
        [-123.139442443848, 49.1844444274902],
        [-123.138854980469, 49.1854438781739],
        [-123.138664245605, 49.1859397888184],
        [-123.138305664062, 49.1867218017579],
        [-123.136863708496, 49.1884498596191],
        [-123.136497497559, 49.1888694763184],
        [-123.136413574219, 49.1891098022461],
        [-123.135887145996, 49.190528869629],
        [-123.136367797852, 49.190746307373],
        [-123.136436462402, 49.1912384033203],
        [-123.136375427246, 49.1914520263672],
        [-123.136253356934, 49.1918716430665],
        [-123.135833740234, 49.1920623779297],
        [-123.135597229004, 49.1920166015626],
        [-123.13500213623, 49.1914672851563],
        [-123.134712219238, 49.1919937133789],
        [-123.134628295898, 49.1941986083986],
        [-123.134635925293, 49.1948432922364],
        [-123.134468078613, 49.1950492858888],
        [-123.134078979492, 49.1954803466797],
        [-123.13370513916, 49.1958770751954],
        [-123.13338470459, 49.1964492797852],
        [-123.133033752441, 49.1966171264649],
        [-123.132766723633, 49.1967163085938],
        [-123.133140563965, 49.1991043090821],
        [-123.124755859375, 49.1987495422363],
        [-123.118354797363, 49.2001953125],
        [-123.109443664551, 49.202205657959],
        [-123.079216003418, 49.2047805786133],
        [-123.079162597656, 49.2047767639161],
        [-123.037643432617, 49.2030944824219],
        [-123.036399841309, 49.2026596069336],
        [-123.023155212402, 49.1980628967286],
        [-123.005424499512, 49.1919021606446],
        [-123.003227233887, 49.1911392211915],
        [-122.999145507812, 49.188575744629],
        [-122.988410949707, 49.1818237304687],
        [-122.983657836914, 49.1811103820802],
        [-122.974311828613, 49.1797103881837],
        [-122.971328735352, 49.1815223693849],
        [-122.969047546387, 49.1829109191895],
        [-122.957893371582, 49.1896896362305],
        [-122.957466125488, 49.1848144531251],
        [-122.95726776123, 49.184928894043],
        [-122.95726776123, 49.1847572326661],
        [-122.95728302002, 49.1841049194337],
        [-122.95728302002, 49.1840057373047],
        [-122.957305908203, 49.1837081909181],
        [-122.957298278809, 49.183620452881],
        [-122.957344055176, 49.1823081970214],
        [-122.957313537598, 49.1820564270021],
        [-122.957321166992, 49.1787223815918],
        [-122.95735168457, 49.1775016784669],
        [-122.957359313965, 49.1772727966309],
        [-122.957382202148, 49.1765670776368],
        [-122.957168579102, 49.1756057739258],
      ],
    ],
  },
}

export const burnabyGeoJson = {
  type: 'Feature',
  geometry: {
    type: 'Polygon',
    coordinates: [
      [
        [-123.023155212402, 49.1980628967286],
        [-123.023002624512, 49.1995315551759],
        [-123.023124694824, 49.2009239196778],
        [-123.023132324219, 49.2010421752931],
        [-123.023139953613, 49.2024688720703],
        [-123.023101806641, 49.2039833068848],
        [-123.023101806641, 49.2041206359864],
        [-123.023094177246, 49.2042694091797],
        [-123.023292541504, 49.2052993774414],
        [-123.023200988769, 49.2067909240723],
        [-123.023254394531, 49.208065032959],
        [-123.023262023926, 49.2086257934571],
        [-123.023307800293, 49.208766937256],
        [-123.023422241211, 49.2091484069825],
        [-123.023239135742, 49.2118492126466],
        [-123.023178100586, 49.2119293212892],
        [-123.023239135742, 49.2126731872559],
        [-123.023200988769, 49.2136611938478],
        [-123.023231506348, 49.214656829834],
        [-123.023231506348, 49.2149047851563],
        [-123.023254394531, 49.215675354004],
        [-123.023262023926, 49.2165565490723],
        [-123.023307800293, 49.2177162170411],
        [-123.023284912109, 49.2183113098146],
        [-123.023315429687, 49.2192192077637],
        [-123.023323059082, 49.220085144043],
        [-123.023315429687, 49.2209777832031],
        [-123.023307800293, 49.2218551635743],
        [-123.023300170898, 49.2222938537598],
        [-123.023323059082, 49.2226867675781],
        [-123.023315429687, 49.225154876709],
        [-123.023323059082, 49.2262992858887],
        [-123.023330688476, 49.2279701232911],
        [-123.023361206055, 49.229232788086],
        [-123.023460388184, 49.2323417663574],
        [-123.023513793945, 49.2344932556153],
        [-123.023529052734, 49.2346534729004],
        [-123.023544311523, 49.2348175048829],
        [-123.023544311523, 49.236213684082],
        [-123.023506164551, 49.2369651794434],
        [-123.023529052734, 49.2374458312988],
        [-123.02352142334, 49.2396202087403],
        [-123.023483276367, 49.2404022216797],
        [-123.02352142334, 49.2410202026367],
        [-123.023506164551, 49.2415618896484],
        [-123.023513793945, 49.2420578002931],
        [-123.023536682129, 49.2426414489746],
        [-123.023506164551, 49.2430114746095],
        [-123.024055480957, 49.2446403503419],
        [-123.023681640625, 49.2448921203613],
        [-123.023651123047, 49.245361328125],
        [-123.02367401123, 49.2461967468261],
        [-123.023689270019, 49.2471275329591],
        [-123.023651123047, 49.2480125427247],
        [-123.023643493652, 49.2489891052247],
        [-123.023719787598, 49.2497863769531],
        [-123.023727416992, 49.2500267028809],
        [-123.023742675781, 49.2505073547364],
        [-123.023750305176, 49.2506980895997],
        [-123.023742675781, 49.2513542175292],
        [-123.023742675781, 49.2515411376954],
        [-123.023727416992, 49.2524757385255],
        [-123.023727416992, 49.2533302307129],
        [-123.023742675781, 49.2542304992676],
        [-123.023719787598, 49.2549324035646],
        [-123.023704528809, 49.2553482055665],
        [-123.023727416992, 49.2555694580079],
        [-123.023712158203, 49.2564353942872],
        [-123.023689270019, 49.2573318481446],
        [-123.023704528809, 49.25834274292],
        [-123.023689270019, 49.259220123291],
        [-123.023704528809, 49.2599143981935],
        [-123.023681640625, 49.2601318359376],
        [-123.023681640625, 49.260555267334],
        [-123.023696899414, 49.261085510254],
        [-123.02367401123, 49.2614173889161],
        [-123.02367401123, 49.2616806030274],
        [-123.02367401123, 49.2620964050293],
        [-123.023666381836, 49.2624702453613],
        [-123.023651123047, 49.2635192871094],
        [-123.023612976074, 49.2657585144044],
        [-123.023597717285, 49.2660942077637],
        [-123.02360534668, 49.2683525085449],
        [-123.023597717285, 49.2685241699219],
        [-123.023582458496, 49.2696304321289],
        [-123.023574829102, 49.2703742980958],
        [-123.023551940918, 49.2713851928712],
        [-123.023544311523, 49.2721977233887],
        [-123.023559570312, 49.2731323242189],
        [-123.02352142334, 49.2739753723145],
        [-123.023536682129, 49.2741851806641],
        [-123.023551940918, 49.2748985290528],
        [-123.023544311523, 49.2749900817872],
        [-123.023559570312, 49.2758140563965],
        [-123.023536682129, 49.2765274047852],
        [-123.023536682129, 49.2767333984376],
        [-123.023544311523, 49.2774734497071],
        [-123.02352142334, 49.2775993347169],
        [-123.023529052734, 49.2783088684082],
        [-123.023513793945, 49.2785110473634],
        [-123.023506164551, 49.2791137695312],
        [-123.023506164551, 49.2794227600098],
        [-123.023506164551, 49.2802200317384],
        [-123.023498535156, 49.2812271118164],
        [-123.023483276367, 49.2820434570313],
        [-123.023483276367, 49.2829704284669],
        [-123.023475646973, 49.2838897705078],
        [-123.023475646973, 49.284740447998],
        [-123.023460388184, 49.28564453125],
        [-123.023460388184, 49.286693572998],
        [-123.0234375, 49.2875518798828],
        [-123.023429870605, 49.287712097168],
        [-123.023460388184, 49.2883949279785],
        [-123.023445129395, 49.2887802124025],
        [-123.023422241211, 49.2893562316895],
        [-123.023429870605, 49.289764404297],
        [-123.023422241211, 49.2899246215821],
        [-123.023498535156, 49.2899017333985],
        [-123.0234375, 49.2931976318361],
        [-123.021705627441, 49.2934303283692],
        [-123.018890380859, 49.293529510498],
        [-123.018417358398, 49.2936134338379],
        [-123.017936706543, 49.2936134338379],
        [-123.017448425293, 49.2934417724609],
        [-123.016944885254, 49.2934532165528],
        [-123.016479492188, 49.2933807373048],
        [-123.015960693359, 49.2933349609376],
        [-123.015243530273, 49.2932205200195],
        [-123.014846801758, 49.2930564880372],
        [-123.014274597168, 49.292881011963],
        [-123.013710021973, 49.2928886413575],
        [-123.012939453125, 49.292808532715],
        [-123.012496948242, 49.2927207946778],
        [-123.01212310791, 49.292823791504],
        [-123.011589050293, 49.292781829834],
        [-123.011047363281, 49.2927513122559],
        [-123.010536193848, 49.2926216125489],
        [-123.010009765625, 49.2925033569336],
        [-123.009490966797, 49.2923889160157],
        [-123.009002685547, 49.2922172546387],
        [-123.008514404297, 49.2920303344728],
        [-123.007789611816, 49.2917861938477],
        [-123.006866455078, 49.2914505004884],
        [-123.006088256836, 49.2911643981935],
        [-123.003494262695, 49.2911872863771],
        [-123.002250671387, 49.290943145752],
        [-123.00154876709, 49.290843963623],
        [-123.000915527344, 49.2907943725586],
        [-123.0, 49.2902793884277],
        [-122.996475219727, 49.2923164367677],
        [-122.995727539062, 49.2928657531739],
        [-122.994873046875, 49.2931442260743],
        [-122.99129486084, 49.2944526672363],
        [-122.99095916748, 49.294734954834],
        [-122.99055480957, 49.2949256896973],
        [-122.990020751953, 49.2948684692383],
        [-122.988456726074, 49.294937133789],
        [-122.98796081543, 49.2949943542482],
        [-122.986381530762, 49.2952346801758],
        [-122.985832214355, 49.2952308654785],
        [-122.985336303711, 49.295021057129],
        [-122.984825134277, 49.2947998046875],
        [-122.984352111816, 49.2944793701172],
        [-122.983848571777, 49.2941741943359],
        [-122.98331451416, 49.293888092041],
        [-122.982749938965, 49.2936096191406],
        [-122.982177734375, 49.2933311462402],
        [-122.978248596191, 49.2916450500488],
        [-122.977745056152, 49.291374206543],
        [-122.97721862793, 49.2911109924317],
        [-122.975830078125, 49.2905540466309],
        [-122.974998474121, 49.2900009155273],
        [-122.974723815918, 49.289722442627],
        [-122.97110748291, 49.2900009155273],
        [-122.97110748291, 49.2902793884277],
        [-122.970085144043, 49.2894477844239],
        [-122.969520568848, 49.289161682129],
        [-122.96898651123, 49.2888832092286],
        [-122.968612670898, 49.2886123657228],
        [-122.968330383301, 49.2886123657228],
        [-122.968055725098, 49.2883338928224],
        [-122.967498779297, 49.2883338928224],
        [-122.967224121094, 49.288055419922],
        [-122.966941833496, 49.288055419922],
        [-122.966667175293, 49.2877769470214],
        [-122.965278625488, 49.2877769470214],
        [-122.962501525879, 49.287498474121],
        [-122.960556030273, 49.2877769470214],
        [-122.95972442627, 49.288055419922],
        [-122.959442138672, 49.2883338928224],
        [-122.959167480469, 49.2883338928224],
        [-122.958595275879, 49.2884635925294],
        [-122.958000183105, 49.2886238098145],
        [-122.957420349121, 49.2888069152833],
        [-122.95694732666, 49.288990020752],
        [-122.956504821777, 49.2891921997071],
        [-122.956085205078, 49.2894325256348],
        [-122.95565032959, 49.2896766662599],
        [-122.955200195312, 49.2899017333985],
        [-122.954696655273, 49.2900238037109],
        [-122.95418548584, 49.29012298584],
        [-122.953659057617, 49.2902259826661],
        [-122.953140258789, 49.2903556823731],
        [-122.952629089355, 49.2905654907227],
        [-122.952110290527, 49.2908058166505],
        [-122.951530456543, 49.2909698486328],
        [-122.950927734375, 49.2911338806153],
        [-122.950340270996, 49.2913246154786],
        [-122.949714660645, 49.2913284301758],
        [-122.949096679688, 49.2911605834961],
        [-122.948448181152, 49.2910575866699],
        [-122.947875976562, 49.2908477783204],
        [-122.947311401367, 49.2906646728516],
        [-122.946792602539, 49.2905960083008],
        [-122.946281433105, 49.2905502319335],
        [-122.945770263672, 49.2904930114746],
        [-122.945243835449, 49.290439605713],
        [-122.944732666016, 49.2905769348145],
        [-122.944229125977, 49.2907638549805],
        [-122.943687438965, 49.2908325195313],
        [-122.943153381348, 49.2909202575684],
        [-122.942680358887, 49.2911949157716],
        [-122.940185546875, 49.2914276123047],
        [-122.939498901367, 49.2915229797364],
        [-122.938835144043, 49.2917137145997],
        [-122.938179016113, 49.2919006347656],
        [-122.937484741211, 49.2919502258302],
        [-122.936790466309, 49.2919502258302],
        [-122.936096191406, 49.2919464111328],
        [-122.935409545898, 49.2919654846193],
        [-122.934730529785, 49.2920379638673],
        [-122.934051513672, 49.2921257019044],
        [-122.933364868164, 49.2922019958496],
        [-122.932670593262, 49.2922172546387],
        [-122.931976318359, 49.2921943664551],
        [-122.931274414062, 49.2921333312989],
        [-122.930534362793, 49.2922821044922],
        [-122.92985534668, 49.2925872802736],
        [-122.929244995117, 49.292953491211],
        [-122.928581237793, 49.2931594848634],
        [-122.927917480469, 49.2930450439453],
        [-122.927276611328, 49.2928504943848],
        [-122.926689147949, 49.2926673889161],
        [-122.926109313965, 49.2925300598146],
        [-122.925521850586, 49.2924461364747],
        [-122.924957275391, 49.2922210693361],
        [-122.924385070801, 49.2919540405274],
        [-122.923789978027, 49.291675567627],
        [-122.923194885254, 49.2914009094238],
        [-122.922584533691, 49.2911262512208],
        [-122.921966552734, 49.2908668518066],
        [-122.921371459961, 49.2905731201172],
        [-122.920783996582, 49.290267944336],
        [-122.920227050781, 49.2899513244629],
        [-122.919639587402, 49.2896614074708],
        [-122.919013977051, 49.2894821166993],
        [-122.918357849121, 49.2894439697266],
        [-122.917694091797, 49.2894439697266],
        [-122.917053222656, 49.2894897460938],
        [-122.916442871094, 49.2896614074708],
        [-122.915786743164, 49.2897186279297],
        [-122.915130615234, 49.2897682189942],
        [-122.914505004883, 49.2899208068848],
        [-122.913879394531, 49.2900543212891],
        [-122.913337707519, 49.2902832031251],
        [-122.912818908691, 49.2905006408691],
        [-122.912246704102, 49.2905502319335],
        [-122.911674499512, 49.2905044555665],
        [-122.911163330078, 49.2903022766113],
        [-122.910675048828, 49.2900772094727],
        [-122.910285949707, 49.2897491455079],
        [-122.909858703613, 49.2894477844239],
        [-122.90941619873, 49.2891731262207],
        [-122.908935546875, 49.2889480590821],
        [-122.908378601074, 49.2888412475586],
        [-122.907836914062, 49.2886695861817],
        [-122.907257080078, 49.28861618042],
        [-122.906669616699, 49.2886085510254],
        [-122.90608215332, 49.2886123657228],
        [-122.905479431152, 49.2886123657228],
        [-122.904884338379, 49.2886123657228],
        [-122.904281616211, 49.2885971069337],
        [-122.903694152832, 49.2885169982911],
        [-122.903129577637, 49.2883758544922],
        [-122.902557373047, 49.2883529663087],
        [-122.901985168457, 49.2884445190429],
        [-122.901428222656, 49.2886276245118],
        [-122.900894165039, 49.2888870239258],
        [-122.900360107422, 49.2891426086426],
        [-122.896286010742, 49.2915191650391],
        [-122.895889282227, 49.2917366027833],
        [-122.895553588867, 49.2920036315918],
        [-122.895210266113, 49.2922744750977],
        [-122.894821166992, 49.2924957275391],
        [-122.894401550293, 49.2926788330078],
        [-122.894325256348, 49.2926864624023],
        [-122.894241333008, 49.2876586914064],
        [-122.894088745117, 49.2877616882325],
        [-122.894187927246, 49.2874603271486],
        [-122.894104003906, 49.2858123779297],
        [-122.893928527832, 49.2823028564454],
        [-122.893699645996, 49.2762908935547],
        [-122.893699645996, 49.2761459350587],
        [-122.893692016602, 49.2760467529296],
        [-122.893684387207, 49.2758407592775],
        [-122.893684387207, 49.2756347656251],
        [-122.8935546875, 49.2709045410156],
        [-122.893539428711, 49.2703323364257],
        [-122.893516540527, 49.2697257995605],
        [-122.893501281738, 49.269100189209],
        [-122.89347076416, 49.2686080932617],
        [-122.89338684082, 49.2675285339357],
        [-122.893394470215, 49.2657051086426],
        [-122.893310546875, 49.2630767822265],
        [-122.893280029297, 49.2606315612794],
        [-122.893211364746, 49.2603073120118],
        [-122.893211364746, 49.2596893310547],
        [-122.893165588379, 49.2595291137696],
        [-122.893142700195, 49.2594642639161],
        [-122.893325805664, 49.2588958740234],
        [-122.893280029297, 49.2587471008302],
        [-122.893218994141, 49.2570152282716],
        [-122.893218994141, 49.2566986083985],
        [-122.893218994141, 49.2561683654785],
        [-122.893135070801, 49.255786895752],
        [-122.893142700195, 49.2555313110352],
        [-122.893112182617, 49.2552375793458],
        [-122.893142700195, 49.2550277709962],
        [-122.893119812012, 49.2543182373047],
        [-122.893035888672, 49.2537879943849],
        [-122.893035888672, 49.2533798217773],
        [-122.89289855957, 49.2520332336426],
        [-122.89289855957, 49.2519531250001],
        [-122.892906188965, 49.2517547607422],
        [-122.892929077148, 49.2510528564454],
        [-122.89282989502, 49.2500801086426],
        [-122.892738342285, 49.2491950988771],
        [-122.89274597168, 49.2482414245607],
        [-122.892692565918, 49.2460746765137],
        [-122.892677307129, 49.2455825805664],
        [-122.892631530762, 49.2426071166993],
        [-122.89266204834, 49.2413978576661],
        [-122.892677307129, 49.2393913269043],
        [-122.892677307129, 49.2392578125],
        [-122.892692565918, 49.2383308410646],
        [-122.892692565918, 49.2381172180176],
        [-122.893005371094, 49.2381248474122],
        [-122.893371582031, 49.2381362915039],
        [-122.893363952637, 49.2376365661621],
        [-122.893569946289, 49.2376365661621],
        [-122.893585205078, 49.2374572753907],
        [-122.894050598145, 49.2374839782715],
        [-122.894035339355, 49.2381477355958],
        [-122.894592285156, 49.2381706237794],
        [-122.894737243652, 49.2372016906739],
        [-122.894859313965, 49.2363548278809],
        [-122.895530700684, 49.2364158630372],
        [-122.895568847656, 49.2358703613281],
        [-122.897010803223, 49.2354927062989],
        [-122.89720916748, 49.2354393005372],
        [-122.897773742676, 49.2352142333986],
        [-122.898414611816, 49.23486328125],
        [-122.899032592773, 49.234531402588],
        [-122.899185180664, 49.2344512939454],
        [-122.899940490723, 49.2340469360353],
        [-122.900276184082, 49.2338867187501],
        [-122.90064239502, 49.2336959838868],
        [-122.901496887207, 49.2332572937012],
        [-122.902420043945, 49.2327537536622],
        [-122.903282165527, 49.232276916504],
        [-122.904411315918, 49.2316665649414],
        [-122.905227661133, 49.2312278747559],
        [-122.906074523926, 49.2307777404785],
        [-122.909141540527, 49.2290954589845],
        [-122.909408569336, 49.2289543151856],
        [-122.911972045898, 49.2276306152344],
        [-122.912139892578, 49.2273750305176],
        [-122.912246704102, 49.2274475097656],
        [-122.916954040527, 49.2249336242676],
        [-122.917350769043, 49.2247238159181],
        [-122.917556762695, 49.2246170043945],
        [-122.919593811035, 49.2235298156738],
        [-122.919868469238, 49.2233810424806],
        [-122.921539306641, 49.2224845886231],
        [-122.921676635742, 49.2226104736329],
        [-122.921844482422, 49.2223205566406],
        [-122.924827575684, 49.2207183837892],
        [-122.925254821777, 49.2204818725587],
        [-122.925910949707, 49.2201156616212],
        [-122.926460266113, 49.2198333740234],
        [-122.927726745605, 49.2191543579103],
        [-122.927841186523, 49.2190856933594],
        [-122.93155670166, 49.2171211242676],
        [-122.934295654297, 49.2156486511232],
        [-122.936134338379, 49.2146530151367],
        [-122.937980651855, 49.2136726379395],
        [-122.939559936523, 49.2128372192383],
        [-122.940254211426, 49.2124328613282],
        [-122.94100189209, 49.212028503418],
        [-122.942581176758, 49.2111625671387],
        [-122.942680358887, 49.2111091613769],
        [-122.944343566894, 49.2102127075196],
        [-122.944725036621, 49.2100067138673],
        [-122.94612121582, 49.2092514038087],
        [-122.946304321289, 49.2091522216797],
        [-122.947814941406, 49.2083358764648],
        [-122.94815826416, 49.208152770996],
        [-122.949958801269, 49.2071914672851],
        [-122.952102661133, 49.2060508728028],
        [-122.953857421875, 49.2050704956056],
        [-122.954833984375, 49.2045364379883],
        [-122.955863952637, 49.2039756774903],
        [-122.957672119141, 49.2030334472656],
        [-122.958557128906, 49.2025680541993],
        [-122.958610534668, 49.2025375366212],
        [-122.96019744873, 49.2016258239746],
        [-122.958099365234, 49.1999893188477],
        [-122.957962036133, 49.1998825073243],
        [-122.957305908203, 49.1994247436523],
        [-122.956802368164, 49.1990699768068],
        [-122.956352233887, 49.1987533569337],
        [-122.955787658691, 49.198356628418],
        [-122.955337524414, 49.1979713439941],
        [-122.954719543457, 49.1974868774414],
        [-122.954406738281, 49.1972389221191],
        [-122.954124450684, 49.1970100402832],
        [-122.953971862793, 49.1968879699708],
        [-122.953819274902, 49.1967201232911],
        [-122.953712463379, 49.1965980529786],
        [-122.953643798828, 49.1965293884278],
        [-122.953552246094, 49.1964302062988],
        [-122.953506469727, 49.1963844299318],
        [-122.95344543457, 49.1963157653809],
        [-122.953323364258, 49.1962356567383],
        [-122.953247070312, 49.1961898803711],
        [-122.953163146973, 49.1961326599122],
        [-122.953094482422, 49.1960945129395],
        [-122.953025817871, 49.1960449218751],
        [-122.952857971191, 49.1959304809571],
        [-122.952713012695, 49.19580078125],
        [-122.95255279541, 49.1956748962402],
        [-122.952461242676, 49.1956062316896],
        [-122.952285766602, 49.195484161377],
        [-122.952178955078, 49.1954078674318],
        [-122.952041625977, 49.19527053833],
        [-122.951942443848, 49.1952095031739],
        [-122.951789855957, 49.1951141357422],
        [-122.951080322266, 49.1945114135743],
        [-122.95743560791, 49.1905593872071],
        [-122.957450866699, 49.1904411315919],
        [-122.95751953125, 49.1899185180665],
        [-122.957893371582, 49.1896896362305],
        [-122.969047546387, 49.1829109191895],
        [-122.971328735352, 49.1815223693849],
        [-122.974311828613, 49.1797103881837],
        [-122.983657836914, 49.1811103820802],
        [-122.988410949707, 49.1818237304687],
        [-122.999145507812, 49.188575744629],
        [-123.003227233887, 49.1911392211915],
        [-123.005424499512, 49.1919021606446],
        [-123.023155212402, 49.1980628967286],
      ],
    ],
  },
}
